.sign-in-default {
    &.edit-user-page {
        display: block;
        // background: rgb(0 0 0 / 40%);
        // ::-webkit-file-upload-button {
        //     display: none;
        // }
        // ::file-selector-button {
        //    display: none;
        // }

        ::-webkit-file-upload-button {
            border: none;
            padding: 5px 10px;
            // font-size: 16px;
            cursor: pointer;
            // background-color: transparent;
            color: rgba(0, 0, 0, 0.5);
            border-radius: 5px;
        }
        ::-ms-browse {
            border: none;
            padding: 5px 10px;
            // font-size: 16px;
            cursor: pointer;
            // background-color: transparent;
            color: rgba(0, 0, 0, 0.5);
            border-radius: 5px;
        }
        &::before {
            display: none;
        }
        .form-box-outer {
            // box-shadow: 1px 1px 10px #b5b5b5;
            padding: 30px;
            background-color: #fff;
            border-radius: 10px;
        }
        .container {
            &::before {
                display: none;
            }
            h1 {
                margin-bottom: 40px;
            }
            .close-btn {
                width: 30px;
                height: 30px;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                position: absolute;
                top: 30px;
                right: 20px;
                cursor: pointer;
                border: 1px #000 solid;
            }
            .form-box {
                max-width: 100%;
                form {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 0px 40px;
                    .input-box {
                        input,select {
                            background-color: transparent !important;
                            -webkit-appearance: listbox !important;
                        }
                        input[type="radio"] {
                            height: 20px;
                            width: 20px;
                            margin-right: 10px;
                            vertical-align: middle;
                        }
                        .heading {
                            margin-bottom: 10px;
                        }
                    }
                    .radio-box {
                        label {
                            display: block;
                            margin-top: 10px;
                            cursor: pointer;
                        }
                    }
                    .submit-box {
                        display: flex;
                        gap: 20px;
                        .cancel-btn {
                            background-color: transparent !important;
                            border: 1px #E53E14 solid;
                            color: #E53E14;
                        }
                    }
                }
            }
        }
    }

}