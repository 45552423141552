
@font-face {
    font-family: 'strawfordregular';
    // src: url('../../../fonts/strawford-regular-webfont.eot');
    src: url('../../assets/fonts/strawford-regular-webfont.eot');
    src: url('../../assets/fonts/strawford-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/strawford-regular-webfont.woff2') format('woff2'),
         url('../../assets/fonts/strawford-regular-webfont.woff') format('woff'),
         url('../../assets/fonts/strawford-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sfiziaregularitalic';
    src: url('../../assets/fonts/Sfizia-RegularItalic.eot');
    src: url('../../assets/fonts/Sfizia-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Sfizia-RegularItalic.woff2') format('woff2'),
        url('../../assets/fonts/Sfizia-RegularItalic.woff') format('woff'),
        url('../../assets/fonts/Sfizia-RegularItalic.ttf') format('truetype'),
        url('../../assets/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'sfiziaregular';
    src: url('../../assets/fonts/Sfizia-Regular.eot');
    src: url('../../assets/fonts/Sfizia-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Sfizia-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Sfizia-Regular.woff') format('woff'),
        url('../../assets/fonts/Sfizia-Regular.ttf') format('truetype'),
        url('../../assets/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'safiro';
    src: url('../../assets/fonts/Safiro-Medium.eot');
    src: url('../../assets/fonts/Safiro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Safiro-Medium.woff2') format('woff2'),
        url('../../assets/fonts/Safiro-Medium.woff') format('woff'),
        url('../../assets/fonts/Safiro-Medium.ttf') format('truetype'),
        url('../../assets/fonts/Safiro-Medium.svg#Safiro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
// font-family: 'safiro', sans-serif;


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    background-color: #fff;
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}
.rubik {
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}
input,textarea,button,a,span,li {
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}
.text-center {
    text-align: center;
}
.sign-in-default {
    padding: 46px;
    position: relative;
    min-height: 100vh;
    display: grid;
    align-items: center;
    overflow: hidden;
    @media (max-width:767px) {
        padding: 20px;
    }

    input::placeholder,
    textarea::placeholder {
        color: rgba(0, 0, 0, 0.8);
    }
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.8);
    }
    input:-moz-placeholder,
    textarea:-moz-placeholder {
        color: rgba(0, 0, 0, 0.8);
    }
    input::-moz-placeholder,
    textarea::-moz-placeholder {
        color: rgba(0, 0, 0, 0.8);
    }
    input, textarea {
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            -webkit-background-clip: text;
            -webkit-text-fill-color: rgba(0, 0, 0, 0.5);
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: inset 0 0 20px 20px #fff;
        }
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }


    &::before {
        content: '';
        display: block;
        background: url('../../assets/images/form-top-pattern.png') 0 0 no-repeat;
        background-size: 100%;
        width: 329px;
        height: 102px;
        position: absolute;
        top: 0;
        right: 10%;
    }
    &::after {
        content: '';
        background-color: #002688;
        width: calc(50% - 300px);
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        @media (max-width:767px) {
            display: none;
        }
    }
    .error {
        color: red;
        font-size: 12px;
        margin-top: 2px;
    }
    .logo {
        position: absolute;
        top: 0;
        @media (max-width:767px) {
            position: relative;
            text-align: center;
        }
        img {
            @media (max-width:767px) {
                max-width: 50px;
            }
        }
    }
    .container {
        position: relative;
        &::before {
            content: "";
            display: block;
            background: rgb(255 255 255 / 8%);
            width: 187px;
            height: 187px;
            position: absolute;
            top: 25%;
            right: -22%;
            border-radius: 50%;
            @media (max-width:767px) {
                display: none;
            }
        }
        &::after {
            content: "";
            display: block;
            background: url('../../assets/images/form-bottom-pattern.png') 0 0 no-repeat;
            background-size: 100%;
            width: 170px;
            height: 328px;
            position: absolute;
            bottom: -100px;
            right: -18%;
            border-radius: 50%;
            @media (max-width:767px) {
                display: none;
            }
        }
    }
    .green-clr {
        color: #00AA2D;
    }
    .black-clr {
        color: #000;
    }
    .container {
        max-width: 1200px;
        width: 100%;
        margin: auto;
        @media (max-width:767px) {
            flex-direction: column;
        }
        h1 {
            font-size: 26px;
            font-weight: 500;
            line-height: 31px;
            color: #363538;
            @media (max-width:767px) {
                font-size: 22px;
            }
        }
        .form-box-outer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            gap: 50px;
            @media (max-width:767px) {
                flex-direction: column;
                gap: 20px;
            }
        }
        .form-box {
            max-width: 400px;
            width: 100%;
            @media (max-width:767px) {
                max-width: 100%;
            }
            .input-box {
                margin-top: 18px;
                position: relative;
                @media (max-width:767px) {
                    margin-top: 10px;
                }
                input,textarea,select {
                    background: #fff !important;
                    color: rgb(0 0 0 / 50%);
                    border: 0;
                    outline: 0;
                    padding: 12px 15px;
                    width: 100%;
                    border-radius: 5px;
                    @media (max-width:767px) {
                        padding: 15px;
                    }
                }
                .input-icon {
                    position: absolute;
                    top: 11px;
                    right: 15px;
                    cursor: pointer;
                    svg {
                        color: rgb(0 0 0 / 30%);
                        font-size: 12px;
                    }
                }
                input[type="file"] {
                    font-size: 10px;
                }
            }
            .profile-box {
                input {
                    color: transparent;
                    padding: 0 !important;
                    border: 0 !important;
                    text-align: center;
                    max-width: 80px;
                }
                img {
                    width: 150px;
                    height: 150px;
                    // object-fit: contain;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-bottom: 10px;
                }
                .relative {
                    width: 230px;
                    height: 230px;
                    box-shadow: 1px 1px 10px #e5e5e5;
                    border-radius: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }
            .submit-box {
                margin-top: 40px;
                input[type="submit"], button {
                    background: #E53E14 !important;
                    border: 0;
                    outline: 0;
                    width: 100%;
                    padding: 10px 20px;
                    border-radius: 8px;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                    cursor: pointer;
                    max-height: 50px;
                    max-width: 160px;
                    display: block;
                    @media (max-width:767px) {
                        padding: 15px;
                        font-size: 16px;
                    }

                }
            }
            .upload-btn {
                background: #E53E14 !important;
                border: 0;
                outline: 0;
                width: 100%;
                padding: 10px;
                border-radius: 8px;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
                max-width: 150px;
                @media (max-width:767px) {
                    padding: 15px;
                    font-size: 16px;
                }
            }
            .remember-check {
                font-size: 12px;
                color: #3E3E3E;
                margin-top: 14px;
                input {
                    margin-right: 8px;
                    vertical-align: middle;
                }
            }
            .link-box {
                margin-top: 14px;
                a {
                    font-size: 12px;
                    color: #08103C;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .img-right {
            position: relative;
            z-index: 1;
            img {
                width: 100%;
            }
        }
        .continue-line {
            position: relative;
            text-align: center;
            font-size: 16px;
            margin-top: 35px;
            @media (max-width:767px) {
                font-size: 12px;
            }
            &::before,&::after {
                content: '';
                width: 112px;
                height: 1px;
                position: absolute;
                top: 10px;
                background-color: #08103C;
                @media (max-width:767px) {
                    top: 7px;
                }
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
            }
        }
        .social-media {
            display: flex;
            gap: 26px;
            justify-content: center;
            margin-top: 26px;
        }
        .bottom-text {
            font-size: 14px;
            line-height: 135%;
            color: #363538;
            margin-top: 25px;
            font-weight: 500;
            @media (max-width:767px) {
                font-size: 12px;
            }
            a {
                color: #4285F4;
                text-decoration: none;
                font-weight: 500;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    &.sign-in-default-v1 {
        background: #1B1B29 url('../../assets/images/loginBg.svg') 0 0 no-repeat;
        background-size: 100%;
        &::before,&::after {
            display: none;
        }
        .container {
            @media (max-width:1440px) {
                max-width: 1100px;
            }
            &::before,&::after {
                display: none;
            }
        }
        .form-box-outer {
            margin-top: -50px;
            @media (max-width:767px) {
                margin-top: 0;
            }
        }
        .logo {
            position: static;
            margin-bottom: 60px;
            @media (max-width:767px) {
                margin-bottom: 20px;
                text-align: left;
            }
        }
        .content-block {
            max-width: 500px;
            h1 {
                font-family: 'sfiziaregularitalic', sans-serif;
                text-transform: none;
                font-size: 100px;
                color: #fff;
                margin-bottom: 20px;
                line-height: 1;
                @media (max-width:1440px) {
                    font-size: 80px;
                }
                @media (max-width:767px) {
                    font-size: 40px;
                    margin-bottom: 10px;
                }
            }
            p {
                color: #EFEFEF;
                font-family: 'strawfordregular', sans-serif;
                font-size: 18px;
                line-height: 1.5;
                @media (max-width:767px) {
                    font-size: 15px;
                }
            }
        }
        .form-box {
            background-color: #fff;
            padding: 40px;
            border-radius: 8px;
            @media (max-width:991px) {
                padding: 20px;
            }
            @media (max-width:767px) {
                max-width: 500px;
            }
            h2 {
                color: #101010;
                font-size: 16px;
                margin-bottom: 24px;
                font-family: 'safiro', sans-serif;
            }
            label {
                color: #191919;
                font-size: 14px;
                margin-bottom: 8px;
                display: block;
            }
            input[type="date"] {
                &::-webkit-calendar-picker-indicator {
                    cursor: pointer;
                    opacity: 0.4;
                    font-size: 16px;
                }
            }
            .submit-box {
                button {
                    max-width: 100%;
                    padding: 16px;
                    max-height: fit-content;
                    @media (max-width:767px) {
                        padding: 12px;
                    }
                }
            }
        }
        &.sign-up-page {
            .content-block {
                h1 {
                    font-size: 50px;
                    @media (max-width:991px) {
                        font-size: 40px;
                    }
                    @media (max-width:767px) {
                        font-size: 32px;
                    }
                }
                p {
                    font-size: 16px;
                    @media (max-width:767px) {
                        font-size: 14px;
                    }
                }
                ul {
                    li {
                        list-style: none;
                        color: #fff;
                        font-size: 14px;
                        margin-top: 10px;
                        img {
                            margin-right: 5px;
                            width: 15px;
                            vertical-align: bottom;
                        }
                    }
                }
                .join-us {
                    color: #F26726;
                    font-size: 14px;
                    text-decoration: underline;
                    display: inline-block;
                    margin-top: 15px;
                }
            }
            .form-box-outer {
                margin-top: -10px;
                @media (max-width: 767px) {
                    margin-top: 0;
                }
            }
            .form-box {
                max-width: 420px;
                h2 {
                    margin-bottom: 10px;
                }
                input[type="checkbox"] {
                    width: 14px;
                    margin-right: 10px;
                }
                .submit-box {
                    margin-top: 20px;
                }
            }
        }
        &.forgot-page {
            .content-block {
                h1 {
                    font-size: 70px;
                    @media (max-width:1320px) {
                        font-size: 60px;
                    }
                    @media (max-width:991px) {
                        font-size: 34px;
                    }
                }
            }
        }
    }


}


.sign-up-page {
    .container {
        .form-box-outer {
            .bottom-text {
                margin-top: 10px;
                .green-clr {
                    font-size: 12px;
                    margin-bottom: 24px;
                }
            }
            .img-right {
                &::after {
                    content: "";
                    display: block;
                    background: rgba(255, 255, 255, 0.08);
                    width: 39px;
                    height: 39px;
                    position: absolute;
                    bottom: -50px;
                    right: 34%;
                    border-radius: 50%;
                    z-index: -1;
                }
            }
        }
    }
}
.mail-sent-popup {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    svg {
        color: #E53E14;
        font-size: 16px;
        width: 40px;
    }
    button {
        background: #E53E14;
        border: 0;
        outline: 0;
        padding: 12px 20px;
        border-radius: 8px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        margin-top: 30px;
    }
    .content-box {
        line-height: 1.5;
        p {
            margin-top: 15px;
        }
    }
}