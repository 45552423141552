@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.container {
  width: 100%;
  display: flex;
  position: relative;
}

.wrapper {
  margin: auto;
  padding: 0 15px;
  margin-top: 30px;
}

.dis-flex {
  display: flex;
  flex-wrap: wrap;
}

.content-center {
  justify-content: center;
}

.content-between {
  justify-content: space-between;
}

h1 {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  margin-top: 8px;
  color: #fff;
}
@media (max-width: 1600px) {
  h1 {
    font-size: 16px;
  }
}

h1, h2, h3 {
  text-transform: capitalize;
  font-weight: 400;
}

.bg-pink {
  background: #FFF5F3 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.bg-red-theme {
  background-color: #E53E14;
  color: #fff;
}

.select-default {
  padding: 8px 10px !important;
  -webkit-appearance: auto !important;
     -moz-appearance: auto !important;
          appearance: auto !important;
  border: 1px #f3f4f6 solid !important;
  border-radius: 5px;
  color: #000 !important;
  font-size: 15px;
  background: #f3f4f6 !important;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}
.select-default option {
  background: #fff !important;
  color: #000 !important;
}

.tox .tox-form label {
  margin-bottom: 5px !important;
  margin-top: 10px !important;
}
.tox .tox-form .tox-textfield {
  color: rgba(0, 0, 0, 0.5) !important;
}
.tox .tox-dialog__footer-end button {
  background-color: #E53E14 !important;
  border-color: #E53E14;
}
.tox .tox-dialog__footer-end button.tox-button.tox-button--secondary {
  background-color: #fff !important;
  border-color: #E53E14;
  color: #E53E14;
}

.default-btn-outer {
  display: flex;
  justify-content: space-between;
}

.default-btn {
  background-color: #E53E14;
  border-radius: 8px;
  padding: 10px 20px;
  display: inline-block;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border: 0;
  outline: 0;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.default-btn.cursor-not-allowed.default-btn {
  cursor: not-allowed;
}
.default-btn svg {
  vertical-align: middle;
}
.default-btn.view-btn::before {
  content: "";
  background: url("../src/assets/images/viewDashboardBtn.svg") 0 4px no-repeat;
  background-size: 100%;
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block;
  margin-right: 10px;
}
.default-btn.prev-btn, .default-btn.next-btn {
  background-color: #F3F3F3;
  color: #363538;
}
.default-btn.prev-btn::before, .default-btn.prev-btn::after, .default-btn.next-btn::before, .default-btn.next-btn::after {
  width: 11px;
  height: 11px;
  position: relative;
  display: inline-block;
  filter: brightness(0.2);
  vertical-align: middle;
}
.default-btn.prev-btn::before {
  content: "";
  background: url("../src/assets/images/prevBtn.svg") 0 0 no-repeat;
  background-size: 100%;
  margin-right: 10px;
}
.default-btn.next-btn::after {
  content: "";
  background: url("../src/assets/images/prevBtn.svg") 0 0 no-repeat;
  background-size: 100%;
  margin-left: 10px;
  transform: rotate(180deg);
}
.default-btn.bg-red-btn {
  background-color: #E53E14;
  color: #fff;
}
.default-btn.bg-red-btn::before, .default-btn.bg-red-btn::after {
  filter: brightness(1);
}
.default-btn.cancel {
  background-color: #fff;
  border: 1px #E53E14 solid;
  color: #E53E14;
}

.light-default-btn {
  background-color: #c9f2ff !important;
}

.font-8 {
  font-size: 8px;
}

.font-9 {
  font-size: 9px;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}
@media (max-width: 1600px) {
  .font-16 {
    font-size: 14px;
  }
}

.font-17 {
  font-size: 17px;
}
@media (max-width: 1600px) {
  .font-17 {
    font-size: 14px;
  }
}

.font-25 {
  font-size: 25px;
}
@media (max-width: 1600px) {
  .font-25 {
    font-size: 18px;
  }
}

.big-font {
  font-size: 100px;
}
@media (max-width: 1600px) {
  .big-font {
    font-size: 90px;
  }
}
@media (max-width: 1320px) {
  .big-font {
    font-size: 80px;
  }
}

.gold {
  color: #D9A25D;
}

.red {
  color: #F78757;
}

.white {
  color: #fff;
}

.green {
  color: #5BC3A3;
}

.silver {
  color: #D2D2D2;
}

.margin-l-20 {
  margin-left: 20px;
}

.margin-l-10 {
  margin-left: 10px;
}

.margin-l-5 {
  margin-left: 5px;
}

.margin-lr-4 {
  margin: 0 4px;
}

.small-gap {
  margin: 0 2px;
}

select, .select, button, input, textarea {
  font-family: "Rubik", sans-serif;
}

select, button, option {
  cursor: pointer;
}
select.cursor-not-allowed.default-btn, button.cursor-not-allowed.default-btn, option.cursor-not-allowed.default-btn {
  cursor: not-allowed;
}

.sidebar {
  background-color: #1B1B29;
  max-width: 265px;
  width: 100%;
  min-height: 100vh;
  padding: 28px 0 28px 28px;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 1600px) {
  .sidebar {
    max-width: 225px;
    padding: 20px 0 20px 15px;
  }
}
@media (max-width: 1550px) {
  .sidebar {
    max-width: 200px;
  }
}
@media (max-width: 991px) {
  .sidebar {
    max-width: 85px;
  }
  .sidebar h1 {
    display: none;
  }
  .sidebar .logo {
    padding-right: 20px;
  }
  .sidebar .logo img {
    max-width: 30px !important;
  }
  .sidebar .menu-title {
    justify-content: center !important;
  }
  .sidebar .hide-text-m {
    display: none;
  }
  .sidebar .sub-menu .sub-head {
    padding: 10px 8px;
    padding-left: 16px;
  }
}
@media (max-width: 991px) and (max-width: 991px) {
  .sidebar .sub-menu .sub-head {
    text-align: center;
  }
}
.sidebar:not(.slide) .logo {
  margin-left: -20px;
}
@media (max-width: 1600px) {
  .sidebar:not(.slide) .logo {
    margin-left: -10px;
  }
}
@media (max-width: 991px) {
  .sidebar:not(.slide) .logo {
    margin-left: 10px;
  }
}
.sidebar:not(.slide) .logo img {
  max-width: 60px;
  margin-left: -10px;
}
.sidebar .logo {
  text-align: center;
  margin-bottom: 15px;
}
.sidebar .menu .menu-item, .sidebar .menu .sub-menu {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}
@media (max-width: 1600px) {
  .sidebar .menu .menu-item, .sidebar .menu .sub-menu {
    font-size: 12px;
  }
}
.sidebar .menu .menu-item {
  position: relative;
}
.sidebar .menu .menu-item::before {
  content: "";
  background-color: rgba(240, 240, 240, 0.6);
  width: 93%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}
.sidebar .menu .menu-item.active .sub-menu {
  display: block;
}
.sidebar .menu .menu-item.active .menu-btn {
  transform: rotate(180deg);
}
.sidebar .menu .menu-item.active .sub-menu-item .sub-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}
.sidebar .menu .menu-item.active .sub-menu-item .sub-title .sub-head {
  width: 100%;
}
.sidebar .menu .menu-item.active .sub-menu-item .sub-title .sub-head:hover:not(.false), .sidebar .menu .menu-item.active .sub-menu-item .sub-title .sub-head.active:not(.false) {
  background-color: transparent;
  border-right: 0;
  border-radius: 0;
}
.sidebar .menu .menu-item.active .sub-menu-item .sub-menu {
  display: none;
  margin-bottom: 0;
}
.sidebar .menu .menu-item.active .sub-menu-item .menu-btn {
  cursor: pointer;
  transform: rotate(0);
}
.sidebar .menu .menu-item.active .sub-menu-item.active .sub-menu {
  display: block;
}
.sidebar .menu .menu-item.active .sub-menu-item.active .menu-btn {
  transform: rotate(180deg);
}
.sidebar .menu .menu-title {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 0;
  cursor: pointer;
}
.sidebar .sub-menu {
  margin-bottom: 10px;
  display: none;
}
.sidebar .sub-menu .sub-head {
  padding: 15px 10px;
  cursor: pointer;
}
@media (max-width: 1600px) {
  .sidebar .sub-menu .sub-head {
    padding: 10px 8px;
  }
}
.sidebar .sub-menu .sub-head:hover:not(.false), .sidebar .sub-menu .sub-head.active:not(.false) {
  background-color: rgba(255, 255, 255, 0.2);
  border-right: 4px #E53E14 solid;
  border-radius: 4px;
}
.sidebar .sub-menu .sub-head.hidenfun {
  background-color: transparent;
  border-radius: 4px;
  border-right-color: transparent;
  color: rgba(204, 204, 204, 0.3411764706);
  pointer-events: none;
}
.sidebar .sub-menu .sub-head.hidenfun img {
  opacity: 0.4;
}
.sidebar .sub-menu .sub-head.hidenfun:hover {
  border-right-color: transparent;
  background-color: transparent;
  cursor: not-allowed !important;
}
.sidebar .sub-menu .sub-head img {
  margin-right: 10px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
}
@media (max-width: 991px) {
  .sidebar .sub-menu .sub-head img {
    margin-right: 20px;
  }
}
@media (max-width: 991px) {
  .sidebar .sub-menu .sub-head svg {
    margin-right: 20px;
  }
}
.sidebar .sub-menu .false {
  cursor: auto;
  opacity: 0.3;
  pointer-events: none;
}
.sidebar.slide {
  max-width: 95px;
}
@media (max-width: 1600px) {
  .sidebar.slide {
    max-width: 85px;
  }
}
.sidebar.slide h1 {
  display: none;
}
.sidebar.slide .logo {
  padding-right: 20px;
}
.sidebar.slide .logo img {
  max-width: 30px;
}
.sidebar.slide .menu-title {
  justify-content: center;
}
.sidebar.slide .hide-text-m {
  display: none;
}
.sidebar.slide .sub-menu .sub-head {
  padding-left: 16px;
}
.sidebar.slide .menu-item.active .sub-menu-item .sub-title {
  display: none;
}
.sidebar.slide .menu-item.active .sub-menu-item .menu-btn {
  display: none;
}
.sidebar.slide .menu-item.active .sub-menu-item .sub-menu {
  display: block;
}

.dashboard {
  background-color: #F9F9F9;
  width: 100%;
  position: relative;
  padding-bottom: 70px;
}
.dashboard select, .dashboard .select {
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  background-position: right;
  padding-right: 10px;
}

.top-header {
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(54, 53, 56, 0.4);
  background-color: #fff;
}
@media (max-width: 1600px) {
  .top-header {
    padding: 5px 15px;
  }
}
.top-header h2 {
  font-size: 24px;
  font-weight: 600;
  color: #000C43;
  display: inline-block;
}
@media (max-width: 1600px) {
  .top-header h2 {
    font-size: 20px;
  }
}
.top-header .hamberger {
  vertical-align: middle;
  margin-right: 10px;
  margin-top: 12px;
  display: inline-block;
  cursor: pointer;
}
@media (max-width: 991px) {
  .top-header .hamberger {
    display: none;
  }
}
.top-header .hamberger span {
  background-color: #33363F;
  width: 20px;
  height: 3px;
  border-radius: 2px;
  display: inline-block;
}
.top-header .hamberger span::before, .top-header .hamberger span::after {
  content: "";
  background-color: #33363F;
  height: 3px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
}
.top-header .hamberger span::before {
  width: 16px;
  top: -4px;
}
.top-header .hamberger span::after {
  width: 10px;
  top: -16px;
}
.top-header .top-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-header .right-box {
  display: flex;
  gap: 22px;
  align-items: center;
}
.top-header .search-box {
  background-color: #EFEFEF;
  border-radius: 5px;
  display: flex;
  padding-left: 12px;
  min-width: 253px;
}
.top-header .search-box input {
  font-size: 12px;
  color: #363538;
  padding: 12px;
  border: 0 !important;
  outline: 0;
  background-color: transparent !important;
  width: 100%;
}
.top-header .notification-box {
  background-color: #FFECE7;
  border-radius: 5px;
  padding: 9px 11px;
  position: relative;
}
.top-header .notification-box .live {
  background-color: #A8F650;
  width: 8px;
  height: 8px;
  position: absolute;
  top: -2px;
  right: 3px;
  border-radius: 50%;
}
.top-header .admin-box {
  display: flex;
  gap: 9px;
  cursor: pointer;
}
.top-header .admin-box img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -o-object-fit: cover;
     object-fit: cover;
}
.top-header .admin-box .name {
  font-size: 16px;
  color: #000;
  text-transform: capitalize;
}
.top-header .admin-box .title {
  font-size: 12px;
  margin-top: 5px;
  color: #363538;
}
.top-header .admin-box .admin-popup {
  display: none;
}
.top-header .admin-box .admin-popup.active {
  position: absolute;
  background: #fff;
  padding: 10px;
  top: 90px;
  min-width: 170px;
  display: block;
  right: 20px;
  box-shadow: 0px 1px 10px #e1e1e1;
  z-index: 9;
  border-radius: 10px;
}
@media (max-width: 1600px) {
  .top-header .admin-box .admin-popup.active {
    top: 64px;
  }
}
.top-header .admin-box .admin-popup.active li {
  list-style-type: none;
}
.top-header .admin-box .admin-popup.active svg {
  width: 18px;
  vertical-align: middle;
  margin-right: 3px;
}

.column-boxes-section .column-boxes-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
}
.column-boxes-section .column-boxes-inner .icon-box {
  background-color: #DDFDFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  margin: auto;
  transition: all 0.3s ease-in-out;
}
.column-boxes-section .column-boxes-inner .col-box {
  flex-basis: 25%;
  padding: 24px 14px 18px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  transition: all 0.2s ease-in-out;
}
.column-boxes-section .column-boxes-inner .col-box img {
  transition: all 0.4s ease-in-out;
}
.column-boxes-section .column-boxes-inner .col-box:hover .icon-box {
  transform: scale(1.1);
}
.column-boxes-section .column-boxes-inner .col-box:hover .icon-box img {
  transform: scale(1.2);
}
.column-boxes-section .column-boxes-inner .col-box h3 {
  margin-top: 14px;
  font-size: 16px;
  color: #000;
}
.column-boxes-section .column-boxes-inner .col-box h6 {
  margin-top: 4px;
  color: rgba(54, 53, 56, 0.5);
  font-size: 10px;
}
.column-boxes-section .column-boxes-inner .col-box .connect-box {
  color: #363538;
  font-size: 14px;
  background-color: #F7F7F7;
  padding: 11px;
  border-radius: 4px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.column-boxes-section .column-boxes-inner .col-box .connect-box img {
  margin-right: 5px;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
}
.column-boxes-section .column-boxes-inner .col-box .connect-box:hover {
  background-color: #E53E14;
  color: #fff;
}
.column-boxes-section .column-boxes-inner .col-box .connect-box:hover img {
  filter: invert(1);
}
.column-boxes-section .column-boxes-inner .col-box .connect .disconnect-icon {
  display: none;
}
.column-boxes-section .column-boxes-inner .col-box .disconnect .connect-icon {
  display: none;
}

.devices-col-section .col-box {
  transition: all 0.2s ease-in-out;
}
.devices-col-section .col-box .icon-box {
  background-color: #DDFDFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  margin: auto;
  transition: all 0.3s ease-in-out;
}
.devices-col-section .col-box img {
  transition: all 0.4s ease-in-out;
}
.devices-col-section .col-box:hover .icon-box {
  transform: scale(1.1);
}
.devices-col-section .col-box:hover .icon-box img {
  transform: scale(1.2);
}
.devices-col-section .connect-box {
  color: #363538;
  font-size: 14px;
  background-color: #F7F7F7;
  padding: 11px;
  border-radius: 4px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.devices-col-section .connect-box img {
  margin-right: 5px;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
}
.devices-col-section .connect-box:hover {
  background-color: #E53E14;
  color: #fff;
}
.devices-col-section .connect-box:hover img {
  filter: invert(1);
}
.devices-col-section .connect .disconnect-icon {
  display: none;
}
.devices-col-section .disconect .connect-icon {
  display: none;
}

.dasboard-footer {
  font-size: 14px;
  color: #000;
  background-color: #F0F0F0;
  padding: 11px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
}

.summary-chart-main {
  display: flex;
  justify-content: space-between;
  gap: 33px;
  margin-top: 20px;
}
@media (max-width: 1200px) {
  .summary-chart-main {
    gap: 20px;
  }
}

.summary-chart-section {
  text-align: center;
  background-color: #fff;
  padding: 45px 40px;
  flex-basis: 70%;
  border-radius: 10px;
}
.summary-chart-section h3 {
  font-size: 20px;
  font-weight: 400;
}
.summary-chart-section .summary-chart-inner {
  margin-top: 30px;
  display: flex;
  gap: 80px;
  justify-content: center;
}
.summary-chart-section .summary-chart-inner .chart-box .chart {
  min-height: 300px;
}
.summary-chart-section .summary-chart-inner .chart-box h4 {
  color: #363538;
  font-size: 16px;
  font-weight: 400;
}

.summary-right-box {
  flex-basis: 30%;
  padding: 20px 20px 40px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  z-index: 0;
}
.summary-right-box.winner-box {
  padding-top: 100px;
  background: linear-gradient(to bottom, rgb(30, 87, 153) 0%, rgb(255, 245, 243) 0%, rgb(255, 255, 255) 100%);
}
.summary-right-box.winner-box::before {
  content: "";
  background: url("../src/assets/images/popperImg.svg") 0 0 no-repeat;
  background-size: cover;
  width: 100%;
  height: 200px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.summary-right-box .summary-img-box {
  text-align: center;
}
.summary-right-box .content-head {
  margin-top: 30px;
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
}
.summary-right-box .content-head h4 {
  font-size: 20px;
  color: #E53E14;
  font-weight: 500;
}
.summary-right-box .content-head p {
  font-size: 12px;
  color: #363538;
  margin-top: 7px;
}
.summary-right-box .monthly-summary-box {
  padding-right: 15px;
}
.summary-right-box .monthly-summary-box::before {
  content: "";
  display: block;
  background: linear-gradient(to right, transparent, #C3C3C3, transparent);
  width: 240px;
  height: 1px;
  position: relative;
  margin: 34px auto;
}
.summary-right-box .monthly-summary-box h5 {
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 20px;
}
.summary-right-box .monthly-summary-box ul li {
  font-size: 11px;
  color: #363538;
  background-color: #F9F9F9;
  border-radius: 4px;
  padding: 11px;
  margin-top: 15px;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.summary-right-box .monthly-summary-box ul li .time {
  font-size: 12px;
  display: block;
  margin-top: 6px;
  font-weight: 500;
}
.summary-right-box .monthly-summary-box ul li .icon-img {
  background-color: #FFFFFF;
  width: 36px;
  height: 36px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-right: 20px;
}

body .tox-tinymce {
  background: none !important;
}

.membership-section .col-box h3 {
  font-size: 30px;
  font-weight: 600;
  text-transform: lowercase;
}
.membership-section .col-box h3 span {
  font-size: 14px;
  color: #363538;
  font-weight: 400;
}
.membership-section .col-box h4 {
  font-size: 23px;
  color: #000;
  margin: 20px 0 6px;
  text-transform: capitalize;
}
.membership-section .col-box p {
  font-size: 13px;
  color: #363538;
}
.membership-section .col-box button {
  width: 100%;
}
.membership-section .col-box ul {
  margin-top: 20px;
}
.membership-section .col-box ul li {
  color: #363538;
  font-size: 13px;
  margin-top: 8px;
  list-style-type: none;
  position: relative;
  padding-left: 25px;
}
.membership-section .col-box ul li::before {
  content: "";
  background: url("../src/assets/images/redTick.svg") 0 4px no-repeat;
  background-size: 100%;
  width: 10px;
  height: 12px;
  display: inline-block;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 4px;
  z-index: 1;
}
.membership-section .col-box ul li::after {
  content: "";
  background: #FFF5F3;
  background-size: 100%;
  width: 17px;
  height: 17px;
  border-radius: 50px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.membership-section .col-box.active-plan {
  background-color: #E53E14;
  color: #fff;
}
.membership-section .col-box.active-plan h3 span {
  color: #fff;
}
.membership-section .col-box.active-plan h4, .membership-section .col-box.active-plan p, .membership-section .col-box.active-plan li {
  color: #fff;
}
.membership-section .col-box .current-plan {
  margin-top: 40px;
  text-align: center;
}
.membership-section .col-box .current-plan h5 {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}
.membership-section .col-box .current-plan .renew-date {
  font-size: 10px;
  color: #fff;
}

.scale-box {
  transition: all 0.3s ease-in-out;
}
.scale-box:hover {
  transform: scale(1.2);
}

table thead {
  position: sticky;
  top: 0;
}

.slick-slider .slick-track {
  display: flex;
  gap: 20px;
}
.slick-slider .slick-list {
  max-width: 1200px;
  margin: auto;
}
@media (max-width: 1600px) {
  .slick-slider .slick-list {
    max-width: 1000px;
  }
}
@media (max-width: 1320px) {
  .slick-slider .slick-list {
    max-width: 800px;
  }
}
@media (max-width: 1150px) {
  .slick-slider .slick-list {
    max-width: 700px;
  }
}
.slick-slider .slick-slide {
  gap: 20px;
}

.bulk-input {
  border: 1px rgba(27, 27, 41, 0.2) solid;
  padding: 40px;
  text-wrap: wrap;
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: underline;
}
.bulk-input input {
  border: 0 !important;
}

.edit-pop-up {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.edit-user-page .input-after {
  position: absolute;
  top: 42px;
  right: 20px;
  color: rgba(153, 153, 153, 0.8);
  font-size: 14px;
}

.content-block-section ul {
  padding-left: 20px;
  margin-top: 10px;
}
.content-block-section ul li {
  margin-top: 5px;
}
.content-block-section h2 {
  font-size: 20px;
  margin-top: 20px;
}
.content-block-section p {
  margin-top: 10px;
  font-size: 16px;
}

.col-package-section .col-package:nth-child(2) .title-box {
  background-color: #2995F7;
}
.col-package-section .col-package:nth-child(3) .title-box {
  background-color: #5348F3;
}
.col-package-section .col-package:nth-child(4) .title-box {
  background-color: #F12292;
}
.col-package-section .col-package:nth-child(5) .title-box {
  background-color: #E8445D;
}
.col-package-section .col-package:nth-child(7) .title-box {
  background-color: #2995F7;
}
.col-package-section .col-package:nth-child(8) .title-box {
  background-color: #5348F3;
}
.col-package-section .col-package:nth-child(9) .title-box {
  background-color: #F12292;
}
.col-package-section .col-package:nth-child(10) .title-box {
  background-color: #E8445D;
}
.col-package-section .col-package:hover .buy-button {
  background-color: #E53E14;
  color: #fff;
}