:root {
  --blue: #1e90ff;
  --white: #ffffff;
}

.App {
  text-align: center;
}

/* Change autoComplete styles in Chrome*/
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(15, 15, 15);
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
  color: rgb(12, 12, 12) !important;
} */

.dayText {
  height: 24px;
  width: 24px;
  padding-top: 2px !important;
}

.eventTitle {
  padding: 0px;
  color: rgb(19, 19, 19);
  white-space: break-spaces;
  text-align: center;
}

.dayCellEvent {
  background-color: #ffffff00 !important;
}

.dayDate {
  text-align: center !important;
}

.calendarDayOfWeek div {
  text-align: center !important;
}

.weekRow {
  display: flex;
}

.dayCell {
  background-color: rgb(234, 230, 230);
  margin: 2px;
}

.border-layout-color {
  border-color: #0f10310c;
}

.border-theme-blue {
  border-color: #17193b38 !important;
}

.bg-theme-blue {
  background: rgb(32, 49, 65);
  background: linear-gradient(140deg, rgba(47, 70, 92, 1) 3%, rgba(27, 68, 88, 1) 35%, rgba(43, 61, 98, 1) 69%, rgba(35, 49, 77, 1) 100%);
}

.bg-theme-blue-card {
  background: linear-gradient(140deg, rgba(27, 68, 88, 1) 4%, rgba(43, 61, 98, 1) 69%);
  border: 1px solid #ffffff21;
}

.bg-theme-side-nav {
  background: rgb(47, 70, 92);
  background: linear-gradient(140deg, rgba(47, 70, 92, 1) 3%, rgba(27, 68, 88, 1) 35%, rgba(43, 61, 98, 1) 69%, rgba(35, 49, 77, 1) 100%);
  /* border-right: 1px solid rgb(25, 60, 78); */

}

.bg-theme-header {
  background: #26455A;
}

.bg-theme-blue-secondary {
  background-color: #1d294194 !important;
}

.bg-light-activity {
  color: #0694F3 !important;
  font-weight: 400;
}

.bg-moderate-activity {
  color: #FB9D0B !important;
  font-weight: 400;
}

.bg-vigorous-activity {
  color: #F50F51 !important;
  font-weight: 400;
}

.button-theme {
  color: rgb(243, 243, 243);
  background-color: #1d294194 !important;
}

.button-theme-secondary {
  color: rgb(183, 180, 180);
  background-color: #161f31 !important;
}

.tox-tinymce {
  background-color: #0f1031 !important;
}

.summary-goal-container {
  /* background-image: url("https://www.freeiconspng.com/thumbs/trophy-png/trophy-png-23.png"); */
  background-repeat: no-repeat;
  background-position: right 0px top 30px;
  background-size: 60vh;
  overflow-x: hidden;
}

.badge-position {
  position: absolute;
  top: 20px;
  left: 22%;
  z-index: 0;
}

.calendar-background {
  background-image: url(assets/fitbit.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-blend-mode: hard-light;
  background-size: 270px;
  background-position-y: 110px;
}

.custom-container {
  background-color: #0f10311f;
}

/* LOADER CSS */

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 8px;
  border-radius: 10px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.48s;
}

.lds-facebook div:nth-child(2) {
  left: 23px;
  animation-delay: -0.36s;
}

.lds-facebook div:nth-child(3) {
  left: 38px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(4) {
  left: 53px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(5) {
  left: 68px;
  animation-delay: 0;
}

:focus {
  outline: none;
}

@keyframes lds-facebook {
  0% {
    top: 0px;
    height: 24px;
  }

  50%,
  100% {
    top: 10px;
    height: 4px;
  }
}

@media (max-width:641px) {
  .summary-goal-container {
    background-image: none;
  }

  .calendar-background {
    background-image: none;
  }

  .badge-position {
    left: 10%;
  }
}

.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #e74c3c;
  /* You can change the confetti color */
  opacity: 0.7;
  transform: rotate(45deg);
  transform-origin: 50% 50%;
  animation: confetti-fall 2s ease-in-out infinite;
}

@keyframes confetti-fall {
  0% {
    transform: translateY(0) rotate(45deg);
    opacity: 0.7;
  }

  100% {
    transform: translateY(500px) rotate(45deg);
    opacity: 0;
  }
}

input,select {
  border: 1px solid #DBE1EA !important;
  background-color: #fff !important;
  background: #fff !important;
  border-radius: 5px;
}

input::placeholder {
  color: rgb(6, 19, 43) !important;
}

/* END LOADER CSS */


/* Style the scrollbar track */
::-webkit-scrollbar {
  width: 4px;
  /* Set the width of the scrollbar */
}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #505d7794;
  /* Background color of the thumb */
  border-radius: 6px;
  /* Rounded corners for the thumb */
}

/* Optionally, you can style the scrollbar track on hover */
::-webkit-scrollbar:hover {
  width: 12px;
}

/* Optionally, you can style the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Optionally, style the scrollbar buttons (top and bottom) */
::-webkit-scrollbar-button {
  display: none;
  /* Hide the buttons */
}

input::placeholder {
  color: rgb(138, 144, 156) !important;
}

/* END LOADER CSS */